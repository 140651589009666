@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto&family=Source+Sans+Pro&display=swap');
@import "../node_modules/animate.css/animate.css";
*{
  margin: 0;  
  overflow: visible;
}


body{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3em;
  line-height: 1.7;
}

:root {
  --tp-dark-blue: rgb(13, 6, 33);
  --tp-medium-blue: rgb(14, 8, 66);
  --tp-light-blue:rgb(25, 17, 101);
  --tp-accent-blue: #3fab9e;
  --tp-accent-hover: #65bcb1;
  --tp-accent-dark: #18937C;
  --tp-light-grey: #FDFDFD;
  --tp-grey:#d2d2d2;
  --base-shadow: 0 4px 23px 0px rgba(0, 0, 0, 0.3);
  --blue-shadow-from: #3fab9e;
  --blue-shadow-to: #1f554f;
  --blue-box-shadow:  0 14px 26px -12px var(--blue-shadow-from), var(--base-shadow), 0 10px 10px -5px var(--blue-shadow-to);
}

/* Common Components */

.visible{
  animation: fadeInUp 1s;
}
.spacer-link{
  height: 75px;
}
button{
  font-size: 0.8em;
}
.button{
  border: none;
  background-color: var(--tp-accent-blue);
  padding: 0.5em 1.3em;
  border-radius: 5px;
  font-family: 'Source Sans Pro', serif;
  font-weight: bolder;
  color: #f0f0f0;
  font-size: 1em;
  box-shadow: 0 10px 10px -5px var(--blue-shadow-to);
}
.button1{
  border: none;
  background-color: var(--tp-accent-blue);
  padding: 0.5em 1.3em;
  border-radius: 5px;
  font-family: 'Source Sans Pro', serif;
  font-weight: bolder;
  color: #f0f0f0;
  /* box-shadow: 0 10px 10px -5px var(--blue-shadow-to); */
  transition: background-color 1s ease;
}

.button1-dark{
  border: none;
  background-color: var(--tp-medium-blue);
  padding: 0.5em 1.3em;
  border-radius: 5px;
  font-family: 'Source Sans Pro', serif;
  font-weight: bolder;
  color: #f0f0f0;
  /* box-shadow: 0 10px 10px -5px var(--blue-shadow-to); */
  transition: background-color 1s ease;
}

.button1:hover{
  background-color: var(--tp-accent-dark);

}

.button1-dark:hover{
  background-color: var(--tp-light-blue);

}

.button2{
  border: none;
  background-color: var(--tp-dark-blue);
  padding: 0.5em 1.3em;
  border-radius: 5px;
  font-family: 'Source Sans Pro', serif;
  font-weight: bolder;
  color: white;
  font-size: 1.1em;
  box-shadow: 0 10px 10px -5px var(--blue-shadow-to);
}

.icon-button{
  border: none;
  background-color: var(--tp-medium-blue);
  padding: 0.8em 0;
  border-radius: 5px;
  font-family: 'Source Sans Pro', serif;
  font-weight: bolder;
  color: #f0f0f0;
  box-shadow: 0 15px 15px -5px var(--tp-dark-blue);
  width: 200px;
}

.button3{
  border: none;
  background-color: transparent;
  padding: 0.2em;
  border-radius: 5px;
  font-family: 'Source Sans Pro', serif;
  color: var(--tp-accent-blue);
  font-size: 0.9em;
  font-weight: bolder;
}


.button4{
  border: 1px solid var(--tp-accent-hover);
  background-color: transparent;
  padding: 0.3em 2em;
  font-family: 'Source Sans Pro', serif;
  color: var(--tp-accent-hover);
  font-size: 0.8em;
  margin: 2em 0;
  border-radius: 4px;
  transition: all 1s ease;
}

.button4:hover{
  background-color: var(--tp-accent-dark);
  color: white;
}


.subheading{
  color: var(--tp-medium-blue);
  padding: 0.2em;

}
.subheading-2{
  font-family: 'Playfair Display', serif;
  color: var(--tp-accent-dark);
  text-align: center;
}

h3{
  font-size: 1.3em;
}

h2{
  font-size: 2em;
}

a{
  text-decoration: none;
}

/* Nav Styling */
.App-logo {
  width: 8%;
  max-width:250px;
  min-width:175px;
}

.logo-small{
  width: 4%;
  max-width:75px;
  min-width:50px;
}
.logo-dark{
  width: 4%;
  max-width:75px;
  min-width:50px;
}
nav{
  background-color: var(--tp-dark-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f0f0f0;
  padding: 0.5em;
  /* position: sticky;
  top: 0;
  z-index: 999; */
}

.nav-emphasis{
  font-weight: bold;
  color: var(--tp-accent-blue);
}
.nav-emphasis-dark{
  font-weight: bold;
  color: var(--tp-accent-dark);
}
.nav-emphasis-white{
  font-weight: bold;
  color: white;
}

nav a{
  color: white;
  font-family: 'Source Sans Pro', serif;
}

.nav-tel{
  margin-right:0.5em;
}

.nav-tel p{
  margin:0.3em;
  text-align: center;
}
.nav-tel a:first-child > p{
  font-size:1.1em;
  animation: pulse 3s infinite;
  text-align: center;
}

/* Nav Sub_menu */
.MuiAppBar-root{
  background-color: var(--tp-accent-blue) !important;
  position: sticky !important;
  top: 0;
  z-index: 999; 
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.MuiContainer-root{
  width: auto !important;
  margin: 0 !important;
}

.MuiAppBar-root a{
  color: white;
}
.MuiButton-root{
  margin: 0.5em 2em !important;
  font-size: 0.8em !important;
  padding: 0 !important;
  color: white !important;
}

.MuiTypography-root{
  margin: 0.8em 0 !important;
  font-family: 'Source Sans Pro', serif !important;
  color: var(--tp-accent-hover) !important;
  font-weight: 500 !important;
}

.MuiMenuItem-root{
  font-size: 0.7em !important;
}
.MuiPopover-paper, .MuiMenuItem-root{
  background-color: var(--tp-dark-blue) !important;
}
.dynamic-call-show{
  display: block;
}
.dynamic-call-hide{
  display: none;
}

#dynamic-call{
  font-weight: bolder;
  animation: lightSpeedInLeft 3s;
  margin-right: 0.8em;

}

.contact-mobile-bar{
  display: none;
}



/* Header/Hero */
video{
  filter: brightness(50%);  
  height:100vh;
  width:100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  border-radius: 25px;
  margin: 2em;
  z-index: 2;
}
.App-header {
  position: relative;
  overflow-x:hidden;
}



.App-header span{
  color: var(--tp-accent-blue);
  font-size: 1em;
}
.App-header h1{
  font-size: 3em;
  color: #fff;
  animation: fadeInUp 2s;
}
.App-header p{
  font-family: 'Playfair Display', serif;
  color: #f0f0f0;
  font-size: 1.2em;
  margin-top: 0.5em;
  font-weight: 500;
  animation-name: fadeInUp, reveal;
  visibility: hidden;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}
.App-header button{
  margin-top: 2em;
}

.video-overlay div:first-child{
  padding: 2em;
  border-left: 4px solid var(--tp-accent-blue);
}
.App-link {
  color: #61dafb;
}
.contact-desktop-span{
  animation-name: fadeInUp, reveal;
  visibility: hidden;
  animation-duration: 2s;
  animation-delay: 4s;
  animation-fill-mode: forwards;
}

/* Main */
main{
  width: 100%;
  margin-top: -1em;
  background-color: white;
}

/* ==================MISSION SECTION ================== */
#mission-section{
  background-color: var(--tp-light-grey);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3em;
}
.slogan{
  font-size: 1em;
  color: var(--tp-medium-blue);
  font-family: 'Playfair Display', serif;
}
.mission-statement{
  display: flex;
  justify-content: center;
}
.mission-statement > div{
  width: 80%;
  padding: 2em;
  text-align: justify;
}
.mission-statement img{
  width: 30%;
}

.mission-statement button{
  display: block;
  margin: 3em auto;
}

#mission-section > h2{
  padding-top: 2em;
}

#mission-section p {
  margin: 0.5em 0;
}


/* ==================MISSION BANNER CONTACT SECTION ================== */

.free-estimate-bar{
  background:  linear-gradient(rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)), url('./assets/images/site/interior_beige.jpeg');
  background-size: cover;
  background-position: top;
  padding: 1em;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.free-estimate-bar > div{
  padding: 2em;
}
.free-estimate-bar h3{
  font-size: 2em;
  text-align: center;
  font-family: 'Source Sans Pro', serif;
  color: white;
  text-shadow: 3px 4px 7px rgba(0,0,0,0.8);
}

.free-estimate-emphasis{
  color: var(--tp-accent-blue);
}

.free-estimate-bar h4{
  color: var(--tp-light-grey);
  font-size: 1em;
  margin: 3em 0;
  font-family: 'Playfair Display', serif;
  text-shadow: 2px 3px 6px rgba(0,0,0,0.8);
}
.free-estimate-bar > div{
  margin-right: 0.5em;
}

/* ==================FEATURE PREVIEW SECTION ================== */
.services-preview{
  display: flex;
  justify-content: space-between;
  margin: 4em 0;
}

.services-preview-inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  max-width: 500px;
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 10%), 0 0 4px 0 rgb(0 0 0 / 8%);
  transition: all 1s ease-in;
  padding-bottom: 1em;
}

.services-preview-inner:hover{
  box-shadow: 0 4px 4px 3px rgb(0 0 0 / 25%), 0 0 8px 0 rgb(0 0 0 / 23%);
}
.services-preview-inner-text{
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.services-preview-inner p{
  text-align: justify;
  padding: 1em;
  font-size: 0.8em;
}

.services-preview img{
  width:100%;

}

.img-hover-zoom {
  overflow: hidden;
  margin-bottom: 1em;
  width:100%;
}

.img-hover-zoom--blur img {
  transition: transform 1s ease-in-out;
  transform: scale(1);
}
.img-hover-zoom--blur:hover img {
  transform: scale(1.2);
}

.services-preview-inner h3{
  text-align: center; 
  color: var(--tp-dark-blue);
  font-family: 'Playfair Display', serif;
  font-size: 1.1em;
}
/* ==================FEATURE SECTION ================== */
.feature{
  background: linear-gradient(rgba(13,6,33, 0.95), rgba(14,8,66,0.95)), url('./assets/images/site/paintbg.jpeg');
  background-size: cover;
  background-position: center;
  padding: 5em 4em;
  display: flex;
  flex-direction: column;
  align-items:  center;
}
.feature-inner{
  margin-top: 2em;
  width: 61.8%;
}
.feature-container{
  display: flex;
  align-items: flex-start;
  margin: 0.3em 0;
}

.feature-inner p{
  margin-bottom: 3em;
}

.feature-container img{
  width: 30px;
  margin-right: 1.5em;
  filter: invert(72%) sepia(70%) saturate(240%) hue-rotate(123deg) brightness(83%) contrast(86%);
}

.feature h2{
  text-align: center;
  color: var(--tp-light-grey);
  font-family: 'Source Sans Pro', serif;
}
.feature h3{
  text-align: center;
  color: var(--tp-accent-blue);
  font-family: 'Playfair Display', serif;
  font-size: 1.2em;
}

.feature h4{
  color: var(--tp-light-grey);
  margin: 1em 0;
}

.feature-container ~ p{
  padding: 0 3em;
  color: var(--tp-light-grey);
  font-size: 0.9em;
  text-align: justify;
}

/* ==================SERVICES SECTION ================== */

.services-top-bar{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.services-top-bar > div{
  margin: 2em 0;
}
.services-top-bar div:first-child{
  display: flex;
}
.services-top-bar img{
  width: 75px;
  height: 75px;
}
.services-top-bar + h4{
  margin: 0.5em;
  text-align: center;
  font-size: 1.2em;
}
.services-top-bar + p {
  text-align: center;
  font-size: 1em !important;

}
.services-section h2{
  text-align: center;
}
.services-section h3{
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  color: var(--tp-dark-blue);
  margin: 1.5em 0 1.5em 0;
}
.services-top-bar p{
  text-align: center;
  margin: 0.5em;
  color: var(--tp-dark-blue) !important;
  font-family: 'Playfair Display', serif;
  font-size: 0.8em;
}

.services-overlay{
  display: flex;
  padding: 2em 0;
}
.services-overlay + h4{
  text-align: center;
  font-size: 1.2em;
}
.services-list-wide{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
}
.services-overlay img{
  width: 100%;
  height:100%;
}
.services-img{
  position: relative;
  display: flex;
  object-fit: initial;
  justify-content: center;
  align-items: center;
}
.services-img:after {
  content: '\A';
  position: absolute;
  width: 100%; height:100%;
  top:0; left:0;
  background:rgba(13,3,66,0.9);
  opacity: 0;
  transition: all 1s;
  -webkit-transition: all 1s;
}
.services-img:hover:after {
  opacity: 1;
} 
.services-img-overlay{
  position: absolute;
  display: none;
  color: white;
  z-index: 10;
}
.services-img:hover .services-img-overlay{
  display: flex;
  flex-direction: column;
}
.services-img-overlay button{
  visibility: hidden;
}
.services-img-overlay a{
  margin: 1em auto;
}
.services-img:hover .services-img-overlay h4{
  animation: fadeInUp 1.5s;
}
.services-img:hover .services-img-overlay button{
  animation: fadeInUp, reveal;
  animation-duration: 1.5s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

.services-section p{
  color: var(--tp-dark-blue);
  font-size: 0.8em;
  padding:0.5em;
}

.services-sub-text{
  padding: 2em;
}

.services-sub-text h4{
  color: var(--tp-dark-blue);
  margin-top: 2em;
}
.services-sub-text h4 + p{
 margin: 1em 0;
}
.services-sub-text h5{
  margin: 0.5em 0;
}
.services-list-container + h5{
  text-align: center;
}
.services-sub-text h3{
  border-bottom: 2px solid var(--tp-accent-dark);
  display: inline;
  padding-bottom: 0.2em;
}
.services-list-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin: 0.5em 0 2em 0;
}
.services-list-container + p{
  text-align: center;
}
.services-list-container ~ a{
  display: block;
  margin: 1.5em 0;
  display: flex;
  justify-content: center;
}
.services-list-item{
  display: flex;
  margin: 0.8em 0;
}

.services-list-item p{
  font-family: 'Source Sans Pro', serif;
  font-weight: 500;
}
.services-list-item img{
  filter: invert(44%) sepia(58%) saturate(589%) hue-rotate(119deg) brightness(92%) contrast(86%);
  width: 20px;  
  margin-right: 10px;
}

.commercial-section{
  margin-bottom: 5em;
  background-color: var(--tp-light-grey);
}

/* ==================PROCESS SECTIONS ================== */

#process-intro{
  background: url('./assets/images/site/white-bg.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  grid-template-rows: auto;
  column-gap: 3em;
}
#process-intro div:nth-child(2){
  display: flex;
  align-items: center;
  justify-content: center;
}
#process-intro div:nth-child(2) img{
  width: 100%;
}

.process-intro-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-align: center;
}

#process-intro h2{
  border-top: 1.5px solid var(--tp-accent-blue);
  border-bottom: 1.5px solid var(--tp-accent-blue);
  margin: 1em 0 2em 0;
}

#process-intro h4{
  margin: 1em 0;
}

.process-intro-text img{
  width:60px;
  height:60px;
}
.process{
  padding: 3em 6em;
}
.process img{
  border-radius: 25px;
}
.process-sub
{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  column-gap: 3em;
}
.process-sub2
{
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  column-gap: 3em;
}

.process-sub-img{
  display: flex;
  align-items: center;
}

.process-sub-img img{
  width: 100%;
}

.process h3{
  color: rgba(255, 255, 255, 0.918);
  margin-bottom: 0.8em;
  border-bottom: 1.5px solid var(--tp-accent-blue);
  font-size: 1.8em;
  display: inline-block;
  padding-right: 2em;
}

.process p{
  color: rgba(255, 255, 255, 0.733);
}

.process1{
  background-color: var(--tp-dark-blue);
}

.process2{
  background-color: var(--tp-medium-blue);
}


/* ==================CONTACT SECTION ================== */

.contact-section{
  background: linear-gradient(rgba(13,6,33, 0.85), rgba(14,8,66, 0.85));
  background-size: contain;
  padding: 6em 3em;
  min-height:700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-section h3{
  color: var(--tp-light-grey);
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-size: 1.5em;
}
.contact-section p{
 font-style: italic;
 color: var(--tp-accent-dark);
}
.contact-section h5{
  color: var(--tp-accent-blue);
}

#form-stage2{
 display: none;
}

#form-stage1, #form-stage2{
  animation: fade 1.5s;
}
#email-box, #phone-box{
  animation: fade 1s;
}

.form-section h3{
  margin-bottom: 1em;
}
.form-section h4{
  color: var(--tp-accent-hover);
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 0.8em;
}
.form-section h5{
  text-align: left;
  margin-top: 2em;
}
.form-section{
  width:50%;
  margin: 1em auto;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#form-status{
  text-align: center;
  color: white;
  font-size:0.7em;
  margin: 0.5em 0;
}
.form-box{
  margin-top: 0.5em;
  display: flex;
  justify-content: center;
}
.MuiFormLabel-root{
  margin: 0.2em 0;
}
.MuiFormControl-root{
  width: 100%;
}

.MuiFormLabel-root, .MuiFormControlLabel-root, .MuiFormControlLabel-label{
  color: white !important;
}

.MuiFormLabel-root{
  margin: 0.8em 0;
}
.MuiFormLabel-root.MuiInputLabel-root{
  margin: 0;
}
.MuiToggleButton-root{
  color: var(--tp-accent-hover) !important;
  width: 125px;
  border:1px solid !important;
}
.MuiToggleButton-root.Mui-selected{
  color: white !important;
}
.MuiRadio-root{
  color: var(--tp-accent-hover) !important;
}
.Mui-checked{
  color: var(--tp-accent-dark)  !important;
}
.Mui-selected{
  background-color: var(--tp-accent-dark) !important;
}
#email-box{
  display: none;
}
.form-btn-box{
  display: flex;
  justify-content: center;
  margin: 1em 0;
}
.form-btn-box button{
  margin: 2em 1em;
}

/*Toggle Switch */
.toggle-group{
  margin: 0.5em 0;
}

.MuiGrid-item{
  color: white;
  font-size: 0.7em;
}

.form-toggle-label{
  color: white;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
}

.MuiSwitch-thumb{
  background-color: var(--tp-accent-hover) !important;
}
.MuiSwitch-track{
  background-color: var(--tp-accent-dark) !important;
}


/* ==================FOOTER SECTION ================== */
footer{
  background-color: var(--tp-dark-blue);
  padding: 1em 3em;
}

.to-top{
  display: flex;
  justify-content: flex-end;
  font-family: 'Playfair Display', serif;
}

.to-top img{
  width: 30px;
  filter: invert(54%) sepia(69%) saturate(337%) hue-rotate(123deg) brightness(95%) contrast(91%);
  margin-left:10px;
}

.to-top a{
  display: flex;
}

.footer-grid{
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: auto;
  column-gap: 5%;
  padding-bottom: 2em;
  border-bottom: 0.1px solid var(--tp-accent-hover);
}

.footer-logo{
  display: inline-flex;
  margin-bottom: 2em;
}

.logo-small{
  width: 50px;
  margin-right: 0.5em;
}

footer h3{
  color: white;
  border-bottom: 2px solid var(--tp-accent-dark);
  padding-right: 4em;
  white-space: nowrap;
}

footer h4{
  color: var(--tp-accent-hover);
  font-family: 'Playfair Display', serif;
  margin-bottom: 1em;
}
footer h5{
  color: var(--tp-light-grey);
}
footer p{
  color: var(--tp-light-grey);
  font-size: 1rem;
}
.backtop{
  border-bottom: 1px solid white;
  display: inline;
  padding-right: 1em;
}
footer a{
  color:var(--tp-light-grey);
  font-size: 1rem;
}

footer ul{
  padding: 0;
}
footer li{
  list-style: none;
}

.footer-icon-container{
  margin-left: 10%;
}

.footer-icon-grp{
  display: flex;
  margin: 5px 0;
}

.footer-icon-grp img{
  width: 20px;
  margin-right: 5%;
  filter: invert(54%) sepia(69%) saturate(337%) hue-rotate(123deg) brightness(95%) contrast(91%);
}

.footer-subtext > a {
  margin-top: 1em;
  display: block;
}

footer > p {
  font-size: 0.8em;
  margin: 0.5em 0;
  text-align: center;
}

.footer-vcenter a{
  font-weight: bolder;
}
/* Animations */
@keyframes reveal {
  to{
    visibility: visible;
  }
}
@keyframes fade {
  from{
    opacity: 0;
  }
  to{
    opacity: 0.7;
  }
}

/* ==============Media Queries ==============*/
@media screen and (max-width: 1700px){
  .form-section{
    width: 30%;
  }
}
@media screen and (max-width: 1500px){
  .form-section{
    width: 40%;
  }
}
@media screen and (max-width: 1400px){
 video{
  width:auto;
 }
}
@media screen and (max-width: 1300px){
  .mission-inner{
    padding: 0 2em;
  }
  .estimate-overlay{
    width: 80%;
  }
}
@media screen and (max-width: 1200px ) {
  .feature-inner{
    width: 80%;
  }
}
@media screen and (max-width: 1100px ) {

  .estimate-overlay{
    width: 100%;
  }
  .form-section{
    width: 50%;
  }
}

@media screen and (max-width: 1000px){
  .services-preview{
    flex-direction: column;
    margin: 0;
    align-items: center;
  }

  .services-preview-inner{
    margin-top: 1em;
    width:90%;
  }

  .footer-grid > div:last-child{
    display: none;
  }

  .footer-grid{
    grid-template-columns: 1fr 1.5fr;
  }

  .process{
    padding: 3em 2em;
  }

  .process-sub{
    display: flex;
    flex-direction: column-reverse;
  }
  .process-sub2{
    display: flex;
    flex-direction: column;
  }
  .process-sub-img{
    display: flex;
    justify-content: center;
    margin: 3em 0;
  }
  .process-sub-img img{
    width: 50%;
  }
}
@media screen and (max-width: 900px ) {
  .App-logo{
    width: 20%;
    max-width:125px;
    min-width:150px;
    margin: 0.5em;
  }
  .logo-dark{
   display: none;
  }

  .video-overlay{
    margin: 2em 0em 0em 2em;
    padding: 0;
  }

  .feature-inner{
    width: 95%;
  }
  .nav-tel{
    font-size: 0.8em;
  }
  .mission-statement{
    flex-direction: column;
  }

  #process-intro{
    display: flex;
    flex-direction: column;
  }
  #process-intro div:nth-child(2) img{
    width: 80%;
  }
  .process{
    padding: 3em 1em;
  }
 
}
@media screen and (max-width: 800px ) {
  .services-list-container{
    grid-template-columns: 1fr 1fr;
  }
  .video-overlay div:first-child{
    padding: 0;
    border-left: none;
  }

  .footer-grid > div:nth-child(2){
    display: none;
  }

  .footer-grid{
    display: flex;
    justify-content: center;
  }

  footer{
    padding:0.5em;
  }
}
@media screen and (max-width: 700px ) {

  .nav-tel > p{
    display: none;
  }
  nav{
    display: none;
  }

  #dynamic-call{
    display: none;
  }
  .contact-desktop-span{
    display: none;
  }

  .contact-mobile-bar{
    display: inherit;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
 
    position: sticky;
    top: 55px;
    z-index: 4;
    height: 40px;
  }
  
  .contact-mobile-bar a{
    color: var(--tp-dark-blue);
  }
  .contact-mobile-bar p{
    animation: pulse 3s infinite;
    font-size: 1.1em;

  }

  .nav-tel{
    display: flex;
    align-items: center;
  }


  .App-header h1{
    font-size: 2.5em;
    animation: none;  
  }

  .App-header p{    
    animation: none;
    visibility: visible;
    font-size: 1.6em;
  }
  .video-overlay{
    padding: 1em;
    margin: 0;
    text-align: center;
  }
  .App-header{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #mission-section{
    padding: 1em;
  }

  .free-estimate-bar{
    background:  linear-gradient(rgba(0,0,0, 0.4), rgba(0,0,0, 0.4)), url('./assets/images/site/interior_beige.jpeg');
    background-position: center;
    background-size: cover;
  }
  .free-estimate-bar > div{
    width: 90%;
    padding: 1em;
  }

  .slogan{
    text-align: center;
  }
  .feature{
    padding: 5em 0.5em;
    background-color: var(--tp-dark-blue);
  }
  .feature-container{
    flex-direction: column;
    align-items: center;
  }
  .feature-container ~ p{
   padding: 0;
  } 
  .feature-container img{
    width:50px;
    filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(176deg) brightness(116%) contrast(88%);
    margin-bottom: 1em;
  }
  .subheading-2{
    color: var(--tp-light-grey);
  }
  .feature h4{
   color: var(--tp-accent-dark);
  } 
  .feature h3{
    color: var(--tp-accent-hover);
  }
  .feature p{
    color: var(--tp-grey);
    text-align: center;
  }

  .services-section{
    padding-top: 3em;
  }
  .services-top-bar img{
    display: none;
  }

  .services-overlay{
    flex-direction: column;
  }
  .services-section h3{
    text-align: center;
  }
  .services-sub-text{
    padding: 1em;
  }

  .form-section{
    width: 80%;
  }
  .process-sub-img img{
    width: 70%;
  }
}

@media screen and (max-width: 500px ) {
  .mission-statement > div{
    width: 90%;
    padding: 1em;
  }
 
  .mission-inner ~ img{
    width: 100%;
  }

  .feature-container img{
    margin-right: 0.5em;
  }

  .App-header h1{
    font-size: 2em;
  }
  .App-header p{
    font-size: 1em;
  }

  .form-section{
    padding: 1em;
  }
  .contact-section{
    /* background: var(--tp-dark-blue); */
    padding: 6em 0;
  }

  .process-sub-img img{
    width: 90%;
  }

  .footer-logo{
    margin-top: 1.5em;
  }
  footer h3{
    padding-right: 2em;
  }
}
@media screen and (max-width:400px ) {
  #mission-section{
    padding: 0.5em;
  }
  .mission-statement > div{
    text-align: center;
    padding: 0.5em;
  }
  footer h3{
    padding-right: 1em;
  }
}
